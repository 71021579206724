import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Eric from "../../components/pages/eric/eric"
import AccessWrapper from '../../components/pages/AccessWrapper'

// console.log(list)

const EricPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Eric Meier' content={content.artists[10]} id={10}>
        <Eric content={content} />
      </AccessWrapper>
    </Layout>
  )
}

export default EricPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`