import React, { useState, useRef, Suspense, useEffect }  from 'react'
import Eric3d from './eric3d';
// import Slideshow from '../../layout/slideshow';
import Slideshow from '../../layout/slideshowNew';
import { SwiperSlide } from 'swiper/react';

import style from './Eric.module.scss'
import Image from '../../elements/image';


function Eric({ content }) {

  const [ state, setState ] = useState({
    isActiveSlideshow: true
  })

  const switchProject = () => {
    console.log('switch project', state)
    setState({ isActiveSlideshow: !state.isActiveSlideshow })
  }

  return (
    <div className={style.eric}>
      {
        state.isActiveSlideshow ?
        <div>
          {/* Currently active slideshow */}
          <Slideshow>
            {
              content.eric_images.map((image, i) => (

                <SwiperSlide key={i} >
                  <div className={style.slide}>
                    <Image src={image.filename} />
                  </div>
                </SwiperSlide>
              ))
            }
          </Slideshow>
        </div>
        :
        <Eric3d content={content} />

      }
      <div className={style.button} onClick={switchProject}>
        Switch to {state.isActiveSlideshow ? 'Gute Gesellschaft' : 'Goodbye Deutschland' }
      </div>
    </div>
  )
}
export default Eric;
