import React, { useEffect, useRef } from 'react'
// import { useThree } from 'react-three-fiber'
import { useThree } from '@react-three/fiber'
import Letter from './Letter'
import { getGLTF, positionOnCircle, positionOnSphere } from './utils'


const config = {
  radius: 20,
  lettersPerFullCircle: 14
}



const Word = ({ meshes, envMap, index, posY }) => {

  const meshRef = useRef()
  const randomVal = Math.random() * 50
  const { camera } = useThree()

  useEffect(() => {

    // Place Word 
    const xCircle = positionOnCircle(index, config.lettersPerFullCircle, config.radius).x
    const zCircle = positionOnCircle(index, config.lettersPerFullCircle, config.radius).z
    const xSphere = positionOnSphere(index, config.lettersPerFullCircle, config.radius).x
    const ySphere = positionOnSphere(index, config.lettersPerFullCircle, config.radius).y
    const zSphere = positionOnSphere(index, config.lettersPerFullCircle, config.radius).z
    console.log(xSphere, ySphere, zSphere, "alt: ", xCircle, undefined, zCircle)
    // meshRef.current.position.x = positionOnCircle(index, config.lettersPerFullCircle, config.radius).x;
    // meshRef.current.position.z = positionOnCircle(index, config.lettersPerFullCircle, config.radius).z;
    meshRef.current.position.x = positionOnSphere(index, config.lettersPerFull, config.radius).x;
    // meshRef.current.position.z = positionOnSphere(index, config.lettersPerFullCircle, config.radius).z;
    meshRef.current.position.y = positionOnSphere(index, config.lettersPerFullCircle, config.radius).y;
    meshRef.current.position.z = positionOnSphere(index, config.lettersPerFullCircle, config.radius).z;
    // meshRef.current.position.y = posY || randomVal;
    // meshRef.current.position.y = randomVal;

    // Rotate towards camera
    // meshRef.current.rotation.x = randomVal * Math.PI
    // meshRef.current.rotation.y = -positionOnCircle(index, config.lettersPerFullCircle, config.radius).rotationY;
    // (randomVal * (360/lettersLength) + 90) * Math.PI / 180 


    meshRef.current.lookAt(camera.position)
    // meshRef.current.lookAt(camera.position[0], camera.position[1], meshRef.current.position.z)
  })

  return(
      <group
      scale={[0.005, 0.005, 0.001] }
      // scale={[1.2, 1.2, 1.2] }
      ref={meshRef}
      >
          {
              meshes.map((mesh, i) => {

                  const props = getGLTF(mesh)

                  return(
                      <Letter 
                      key={i}
                      {...props}
                      envMap={envMap}
                      index={randomVal}
                      posY={randomVal}
                      />
                  )
              })
          }
      </group>
  )
}

export default Word