import React, { useRef, useEffect } from 'react'
import { a } from '@react-spring/three';
import * as THREE from 'three'


// const config = {
//   radius: 20,
//   lettersPerFullCircle: 14
// }

const Letter = (props) => {

  const { geometry, vertices, count, position, envMap, index } = props
  const meshRef = useRef()

  console.log('Letter is rendered', position.x)

  const offsetPosition = new THREE.Vector3(
    position.x,
    position.y+30,
    // position.z,
    10
  )

  useEffect(() => {
    // meshRef.current.position.x = positionOnCircle(index, config.lettersPerFullCircle, config.radius).x;
    // meshRef.current.position.z = positionOnCircle(index, config.lettersPerFullCircle, config.radius).z;
    // meshRef.current.position.y = Math.random() * 50;
    // meshRef.current.rotation.x = -positionOnCircle(1, config.lettersPerFullCircle, config.radius).rotationY;
    meshRef.current.rotation.x = props.posY/100
  })


  return(
      <mesh
      ref={meshRef}
      dispose={null}
      receiveShadow
      castShadow
      position={offsetPosition}
      // scale={[0.01, 0.01, 0.01] }
      // scale={[0.1, 0.1, 0.1] }
      // scale={[1, 1, 6] }
      // onPointerOver={() => setState({...state, active: !state.active})}
      >
          <bufferGeometry 
          attach="geometry" 
          setFromPoints={vertices}
          {...geometry}
          >
              <bufferAttribute 
                  attachObject={['attributes', 'position']} 
                  // count={vertices.length / 3} 
                  count={count} 
                  array={vertices} 
                  itemSize={3} 
              /> 

          </bufferGeometry>
          <a.meshPhongMaterial 
          attach="material" 
          color="white" 
          metalness={20} 
          roughness={0} 
          envMap={envMap} 
          specular={0xffffff}
          flatShading={true} 
          transparent={true}
          opacity={1}
          // {...springProps}
          />
      </mesh>
  )
}

export default Letter