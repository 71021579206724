import React from "react"
// import * as THREE from 'three'
import { useGLTF } from '@react-three/drei'
import Word from './Word'


const Words = ({file, envMap}) => {

    const obj = useGLTF(file)
    // console.log(obj)
    const meshes = obj.nodes.MoText.children
    const LEN = 14;
    const wordCount = new Array(LEN).fill(0);

    return(
        <group>
           
            {
                wordCount.map((mesh, i) => {

                    console.log('Inside Words loop')

                    return(
                        <Word 
                        key={i}
                        // {...props}
                        index={i}
                        meshes={meshes}
                        envMap={envMap}
                        index={i}
                        posY={1}
                        />

                    )
                })
            }
           
           {/* {
               wordCount.map((mesh, i) => {

                   console.log('Inside Words loop')

                   return(
                       <Word 
                       key={i}
                       // {...props}
                       index={i}
                       meshes={meshes}
                       envMap={envMap}
                       index={i}
                        posY={10}
                       />

                   )
               })
           }
           {
               wordCount.map((mesh, i) => {

                   console.log('Inside Words loop')

                   return(
                       <Word 
                       key={i}
                       // {...props}
                       index={i}
                       meshes={meshes}
                       envMap={envMap}
                       index={i}
                        posY={20}
                       />

                   )
               })
           }
           {
               wordCount.map((mesh, i) => {

                   console.log('Inside Words loop')

                   return(
                       <Word 
                       key={i}
                       // {...props}
                       index={i}
                       meshes={meshes}
                       envMap={envMap}
                       index={i}
                        posY={50}
                       />

                   )
               })
           } */}
        </group>
    )
}

export default Words
