export const getGLTF = (object) => {
  // console.log(object)
  // const geometry = computeGeometry(object)
  const geometry = object.geometry
  const material = object.material
  const position = object.position

  // const vertices = geometry.index.array;
  const vertices = geometry.attributes.position.array;
  const data = geometry.attributes.position;
  const count = geometry.attributes.position.count;

  // console.log(vertices, data, count)
  return { geometry, material, vertices, count, data, position }
}

export const positionOnCircle = (index, lettersLength, radius) => {

  // const u = 2*Math.PI*radius
  const segAngle = 360 / lettersLength

  // get position by indes
  const segRad = segAngle * Math.PI / 180

  // Shifting along the circumference
  const currentSegment = index + 1
  const alpha = segRad * currentSegment
  const x = Math.cos(alpha) * radius
  const z = Math.sin(alpha) * radius
  // console.log("x, z", x, z)

  return { 
    x, 
    z, 
    rotationY: (index * (360/lettersLength) + 130) * Math.PI / 180,
    rotationZ: (index * (360/lettersLength) + 90) * Math.PI / 180 
  }
}
export const positionOnSphere = (index, lettersLength, radius) => {

  // const nx = 4;
  // const ny = 5

  // const lon = 360 * ((index+0.5) / nx)
  // const midpt = (index+0.5) / ny                                  
  // const lat = 180 * Math.asin(2*((index+0.5)/ny-0.5))   
  const theta = index;
  const phi = Math.ceil(index/3);  

  const x = radius * Math.sin(theta) * Math.cos (phi);
  const y = radius * Math.sin(theta) * Math.sin (phi);
  const z = radius * Math.cos(theta);

  return { x, y, z }

}