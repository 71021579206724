import React, { useState, useRef, Suspense, useEffect }  from 'react'
import * as THREE from 'three'
// import { 
//     Canvas, 
//     useFrame,
//     useThree,
//     extend 
// } from 'react-three-fiber'
import { 
  Canvas, 
  useFrame,
  useThree,
  extend 
} from '@react-three/fiber';
// import Controls from '../../threejs/controls'
import Skybox from '../../threejs/skybox'

// import './styles.css'
import { OrbitControls } from '@react-three/drei'
// import Loader from './loader'
import Watermarks from './watermark/Watermarks'
import style from './Eric.module.scss'

// extend( 'EXT_color_buffer_float' );
extend( 'OES_texture_half_float' );
extend( 'OES_texture_half_float_linear' );

function Scene({ content }) {

  const fileUrl = content.erictypefile.filename

  const [renderTarget] = useState(new THREE.WebGLCubeRenderTarget(2048, { 
    format: THREE.RGBAFormat, 
    generateMipmaps: true,
    coding: THREE.sRGBEncoding, // temporary -- to prevent the material's shader from recompiling every frame
    type: THREE.HalfFloatType,
    minFilter: THREE.LinearFilter, 
    magFilter: THREE.NearestFilter,
    // cacheAsBitmap: false,
    // autoClear: true
  }))

  const cubeCamera = useRef()
  const perspectiveCamera = useRef()

  useFrame(({ gl, scene }) => {
    
    // materialRef.current.envMap = renderTarget.texture;

    if(cubeCamera.current){
      cubeCamera.current.update(gl, scene)
       
    }
    if(perspectiveCamera.current){
      perspectiveCamera.current.updateProjectionMatrix(gl, scene)
       
    }
  })
  
  const { scene } = useThree()

  // useEffect(() => {
    // scene.fog = new THREE.Fog("rgba(220,220,220,0.4)", 400,2400);
    
  // }, [])

  
  return (
    <>
      <Skybox 
        images={[
          content.backgrounds[0].px.filename,
          content.backgrounds[0].nx.filename,
          content.backgrounds[0].py.filename,
          content.backgrounds[0].ny.filename,
          content.backgrounds[0].pz.filename,
          content.backgrounds[0].nz.filename,
        ]}
      />
      {/* https://snayss.medium.com/three-js-fog-hacks-fc0b42f63386 */}
			{/* <fog args={['white', 0.1, 10]} /> */}
      {/* <Sky layers={[11]} sunPosition={[Math.PI, 0, Math.PI / 2]} turbidity={8} rayleigh={6} mieCoefficient={0.0005} mieDirectionalG={0.8} /> */}
      

      <cubeCamera layers={[11]} name="cubeCamera" ref={cubeCamera} position={[0, 30, 0]} args={[0.1, 1000, renderTarget]} />
      {/* <perspectiveCamera layers={[11]} name="perspectiveCamera" ref={perspectiveCamera} position={[0, 30, 0]} args={[45, 0.6, 1, 1000]} /> */}
      
      {/* <ambientLight intensity="20"/> */}
      <pointLight position={[10, 10, 0]} color="white" intensity="0.1"/>
      {/* <pointLight position={[10, 200, 0]} color="red" intensity="0.6"/> */}
      {/* <pointLight position={[10, -200, 0]} color="yellow" intensity="5"/>
      <pointLight position={[10, 400, 0]} color="blue" intensity="15"/> */}

      <Suspense fallback={null}>
          {/* <Loader file={fileUrl} position={[-1,1,0]} envMap={renderTarget.texture}></Loader> */}
          <Watermarks file={fileUrl} position={[-1,1,0]} envMap={renderTarget.texture} ></Watermarks>
          {/* <Loader file={fileUrl} position={[1,1,0]} envMap={renderTarget.texture}></Loader> */}
      </Suspense>

      {/* <Controls /> */}
      <OrbitControls
      enableZoom={false}
      autoRotate={true}
      autoRotateSpeed={0.01}
        
      />
      <ambientLight />
    </>
  )
}


function Eric3d({ content }) {
  // let pixelRatio = typeof window !== 'undefined' && window.devicePixelRatio ? window.devicePixelRatio : 1
  // // let pixelRatio = 1
  // console.log(pixelRatio)

  let pixelRatio = 1

  useEffect(() => {
    pixelRatio = typeof window !== 'undefined' && window.devicePixelRatio ? window.devicePixelRatio : 1
    
  }, [])

  // return(
  //   <>
  //     <Canvas 
  //     width="100vw"
  //     height="100vh"
  //     className="canvas"
  //     background="red"
  //     >
  return (
    <>
      <Canvas
      width="100vw"
      height="100vh"
      className="canvas"
      // background="red"
      colorManagement
      // fov 90
      camera={{ position: [0, 0, 10], fov: 70, near: 15, far: 1500 }}>
        <Scene content={content}/>
      </Canvas>
      <style>
        {`
          .canvas{
            overflow: visible;
          }

          .canvas canvas{
            position: relative;
            height: ${100*pixelRatio}vh;
            transform: scale(${1/pixelRatio}, ${1/pixelRatio});
            transform-origin: 50% 0;
          }
        `}
      </style>
    </>
  )
}
export default Eric3d;
