import React from 'react'
import {  useThree } from '@react-three/fiber';
// import {  useThree } from 'react-three-fiber'
import { BoxBufferGeometry, CubeTextureLoader } from "three";
import * as THREE from 'three'
import { useRef } from 'react';

// Loads the skybox texture and applies it to the scene.
function SkyBox({ images, offset }) {

    const { scene } = useThree();
    // scene.rotateY(offset);
    // scene.rotation.y = Math.PI / 2 ;
    // scene.rotateOnAxis(new THREE.Vector3(0, 1, 0), offset)

    const loader = new CubeTextureLoader();

    const texture = loader.load(images)
    texture.encoding = THREE.sRGBEncoding
    scene.background = texture;
    return null;

}

export default SkyBox


// const SkyBoxRotate = ({ images, offset }) => {

//     var materialArray = [];
//     for (var i = 0; i < 6; i++)
//     materialArray.push( new THREE.MeshBasicMaterial({
//     map: THREE.ImageUtils.loadTexture( images[i] ),
//     side: THREE.BackSide
//     }));

//     var skyGeometry = new THREE.CubeGeometry( 5000, 5000, 5000 );
//     var skyMaterial = new THREE.MeshFaceMaterial( materialArray );
//     var skybox = new THREE.Mesh( skyGeometry, skyMaterial );


//     return(
//         <mesh>
//             <cubeBufferGeometry
//             attach="geometry"
//             args={[5000,5000,5000]}
//             />
//             <meshFaceMaterial />
//         </mesh>
//     )
// }